import React from "react";
import {MainLayout} from "../../components/Organisms/layouts";

export function Maintain() {
  return (
    <MainLayout>
      <div style={{ padding: "10px" , width: "100%", textAlign: "center" }}>
        <div style={{ padding: "20px", textAlign: "center",  width: "600px", paddingTop: "10%" , margin: "auto" }}>
          <h3 style={{ color: "black", fontSize: "22px", fontWeight: "bold" }}>
            🛑  Reminder: Walmart CPC Process Update
          </h3>
          <div style={{  marginTop: "40px", textAlign: "center", fontSize: "16px"}}>
            Please note that seller is no longer required to submit GCC/CPC via iCW platform. All sellers must now follow the new Walmart CPC process. If you have any questions, please contact <a
            href="mailto:pscmarketplace@wal-mart.com">pscmarketplace@wal-mart.com</a>.

          </div>
        </div>
      </div>
    </MainLayout>

  )
}
